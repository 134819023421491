html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
* {
    box-sizing: border-box;
    outline: none;
    font: inherit;
}
a {
    text-decoration: none;
}
html,
body,
#__next {
    height: 100%;
}
.hidden-scroll {
    overflow: moz-scrollbars-none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.hidden-scroll::-webkit-scrollbar {
    display: none;
}
#root {
    height: 100%;
}
.react-timerange-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 3 + 0.217em * 2);
}
