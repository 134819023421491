@font-face {
    font-family: "Helvetica Neue";
    src: url("HelveticaNeue.ttf");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Helvetica Neue";
    src: url("HelveticaNeueBold.ttf");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: "Helvetica Neue";
    src: url("HelveticaNeueUltraLightItalic.ttf");
    font-weight: normal;
    font-style: italic;
}
